import './LottieLoading.css';
import React, {useEffect, useRef} from 'react';
import lottie from 'lottie-web/build/player/lottie_light';
import animationData from '../lottie/loading-walk.json';
import {Box, Typography} from "@mui/material";

const LottieLoading = () => {
  const animationContainer = useRef(null);
  const anim = useRef(null);

  useEffect(() => {
    if (animationContainer.current) {
      anim.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });

      return () => anim.current?.destroy();
    }
  }, []);

  return (
    <Box
      id={'lottie-loading-container'}
    >
      <div
        ref={animationContainer}
      ></div>
      <Typography align="center" variant="h6" gutterBottom>
        Chargement du contenu en cours
      </Typography>
    </Box>
  );
};

export default LottieLoading;
