import {
  FeatureCollection
} from "geojson";
import {LngLat} from "mapbox-gl";

export class ElevationProfile {
  distance: number;
  positiveElevation: number;
  negativeElevation: number;
  data: 'custom';
  options: 'custom';

  constructor(distance, positiveElevation, negativeElevation, data, options) {
    this.distance = distance;
    this.positiveElevation = positiveElevation;
    this.negativeElevation = negativeElevation;
    this.data = data;
    this.options = options;
  }
}

// https://gitea.openium.fr/openium/openium-app-template.ios/src/commit/8f427a747ca2c80afe9e484c93628525e1778560/Template/Classes/Controllers/Modules/OpenExplore/ElevationProfile/ModuleOpenExploreElevationProfileViewController.swift#L281
export const getClosestPoint = (point: LngLat, geoJson: FeatureCollection) => {
  let closestPoint = undefined;
  let closestIndex = undefined;
  let closestDistance = Number.MAX_SAFE_INTEGER;

  geoJson.features.forEach((feature) => {
    console.log("GeoJSON Feature", feature);

    // Point | MultiPoint | LineString | MultiLineString | Polygon | MultiPolygon | GeometryCollection;
    if (feature.geometry.type === "LineString") {
      feature.geometry.coordinates.forEach((coordinate, index) => {
        let coordinatePoint = new LngLat(coordinate[0], coordinate[1])
        let distance = point.distanceTo(coordinatePoint);

        if (distance < closestDistance) {
          closestDistance = distance;
          closestPoint = coordinatePoint;
          closestIndex = index;
        }
      })
    }
  })

  return [closestPoint, closestIndex];
};

// https://gitea.openium.fr/openium/openium-app-template.ios/src/commit/8f427a747ca2c80afe9e484c93628525e1778560/Template/Classes/Controllers/Modules/OpenExplore/ElevationProfile/ModuleOpenExploreElevationProfileViewController.swift#L344
export const getElevationProfile = (startPoint: LngLat, startPointIndex: number, endPoint: LngLat, endPointIndex: number, geoJson: FeatureCollection) => {
  let totalDistance = 0
  let positiveElevation = 0
  let negativeElevation = 0
  let previousPoint = undefined;
  let previousAltitude = undefined;
  let xAxis = [];
  let yAxis = [];

  geoJson.features.forEach((feature) => {
    console.log("GeoJSON Feature", feature);

    // Point | MultiPoint | LineString | MultiLineString | Polygon | MultiPolygon | GeometryCollection;
    if (feature.geometry.type === "LineString") {
      let coordinates = feature.geometry.coordinates;

      if (startPointIndex < endPointIndex) {
        coordinates = coordinates.slice(startPointIndex, endPointIndex);
      } else {
        const newStartIndex = coordinates.length - startPointIndex;
        const newEndIndex = coordinates.length - endPointIndex;

        coordinates = coordinates.reverse();
        coordinates = coordinates.slice(newStartIndex, newEndIndex);
      }

      coordinates.forEach((coordinate) => {
        let currentPoint = new LngLat(coordinate[0], coordinate[1]);
        let currentAltitude = coordinate[2];

        if (previousPoint === undefined) {
          previousPoint = currentPoint;
        }
        if (previousAltitude === undefined) {
          previousAltitude = currentAltitude;
        }

        totalDistance += previousPoint.distanceTo(currentPoint);
        if (previousAltitude > currentAltitude) {
          negativeElevation += previousAltitude - currentAltitude
        } else {
          positiveElevation += currentAltitude - previousAltitude
        }

        previousPoint = currentPoint;
        previousAltitude = currentAltitude;

        xAxis.push(Math.round(totalDistance / 1000));
        yAxis.push(currentAltitude);
      })
    }
  })

  return new ElevationProfile(
    totalDistance,
    positiveElevation,
    negativeElevation,
    {
      labels: xAxis,
      datasets: [
        {
          label: "Altitude",
          data: yAxis,
          borderColor: '#1F3060',
          pointRadius: 0,
          backgroundColor: '#1F3060',
          fill: false,
          cubicInterpolationMode: 'monotone',
          tension: 0.4
        }
      ]
    },
    {
      responsive: true,
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false
        }
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Distance (km)'
          }
        },
        y: {
          title: {
            display: true,
            text: 'Altitude (m)'
          }
        }
      }
    }
  );
}