import axios from 'axios';
import {apiOpenExplorePoints, apiOpenExploreTrails, apiOpenExploreTypes, apiOpenExploreUrl} from "../constants/apis";
import {gpx} from "@tmcw/togeojson";

const DOMParser = require("xmldom").DOMParser

export const apiGetOpenExplorePoints = async () => {
  const data = await axios.get(
    `${apiOpenExploreUrl}${apiOpenExplorePoints}`,
    {headers: {'Accept-Language': `fr`}}
  );
  return data;
};

export const apiGetOpenExploreTrails = async () => {
  const data = await axios.get(
    `${apiOpenExploreUrl}${apiOpenExploreTrails}`,
    {headers: {'Accept-Language': `fr`}}
  );
  return data;
};

export const apiGetOpenExploreTypes = async () => {
  const data = await axios.get(
    `${apiOpenExploreUrl}${apiOpenExploreTypes}`,
    {headers: {'Accept-Language': `fr`}}
  );
  return data;
};

export const apiDownloadGPXToGeoJSON = async (trail) => {
  const data = await axios.get(trail.gpx);
  const parsedGPX = new DOMParser().parseFromString(data.data)

  return gpx(parsedGPX);
};