import './PointDetail.css';
import React, {useEffect} from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import {Box, Fab, IconButton, Tab, Tabs, Typography} from "@mui/material";
import Map, {Layer, Marker, Source} from "react-map-gl";
import {ignLayer} from "../constants/mapbox";
import CloseIcon from "@mui/icons-material/Close";
import NavigationIcon from '@mui/icons-material/Navigation';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const PointDetail = ({point, types, onClose}) => {
  const [type, setType] = React.useState(undefined);
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (type === undefined) {
      const typeId = point.types_id[0]

      if (typeId) {
        const type = types.filter((type) => type.id === typeId)[0]
        setType(type);
      }
    }
  }, [point.types_id, type, types]);

  const images = point.medias.map((media) => {
      return {
        original: media.link,
        thumbnail: media.link
      }
    }
  );

  return (
    <React.Fragment>
      <IconButton
        sx={{
          color: "white",
          backgroundColor: "#1F3060",
          '&:hover': {
            color: "white",
            backgroundColor: "#031427",
          },
          position: "absolute",
          right: 16,
          top: 16,
          zIndex: 2
        }}
        onClick={() => {
          onClose();
        }}
      >
        <CloseIcon/>
      </IconButton>
      {/* https://github.com/xiaolin/react-image-gallery */}
      <ImageGallery
        items={images}
        autoPlay={true}
        showPlayButton={false}
        showBullets={true}
        showThumbnails={false}
        showNav={false}
        thumbnailPosition={'bottom'}
      />
      <Box sx={{padding: 2}}>
        <Typography align="left" variant="h6">
          {point.name}
        </Typography>
        {type && (
          <Typography
            align="left"
            variant="subtitle"
            className={"detailType"}
          >
            {type.name}
          </Typography>
        )}
      </Box>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs
          className={"detailTabbar"}
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Description" {...a11yProps(0)} />
          <Tab label="Plan d'accès" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box sx={{p: 3}}>
          <Typography
            dangerouslySetInnerHTML={{__html: point.description}}>
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Map
          initialViewState={{
            latitude: point.latitude,
            longitude: point.longitude,
            zoom: 14
          }}
          cooperativeGestures={true}
          mapboxAccessToken={'pk.eyJ1IjoidXJiYWludiIsImEiOiJjbDc4MGd6aXgwNTJtM29tYzB6c2U1ZXN1In0.VRuSsYOx80GutNwNmMaWXA'}
          style={{height: '500px'}}
          mapStyle='mapbox://styles/mapbox/outdoors-v11'
        >
          {/* IGN Layer */}
          <Source
            id='ignSource'
            type='raster'
            tiles={["https://wxs.ign.fr/cartes/geoportail/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2&TILEMATRIXSET=PM&TILEMATRIX={z}&TILECOL={x}&TILEROW={y}&STYLE=normal&FORMAT=image/png"]}
            tileSize={256}
          >
            <Layer {...ignLayer} />
          </Source>
          <Marker
            latitude={point.latitude}
            longitude={point.longitude}
            anchor={type ? "center" : "bottom"}
            color={'#BF0603'}
            key={point.id}
          >
            {type && (<img src={type.picture} width={30} alt={""}/>)}
          </Marker>
          <Fab
            sx={{
              position: "absolute",
              color: "white",
              backgroundColor: "#1F3060",
              '&:hover': {
                color: "white",
                backgroundColor: "#031427",
              },
              top: 16,
              right: 16,
              zIndex: 2
            }}
            variant="extended"
            onClick={() => {
              const url = "https://www.google.com/maps/dir/?api=1&destination=" + point.latitude + "," + point.longitude;
              window.open(url, '_blank', 'noopener,noreferrer');
            }}
          >
            <NavigationIcon sx={{mr: 1}}/>
            S'y rendre
          </Fab>
        </Map>
      </TabPanel>
    </React.Fragment>
  );
};

export default PointDetail;
