import './App.css';
import React, {useEffect} from 'react';
import Map, {Layer, Marker, Source} from 'react-map-gl';
import LottieLoading from "./components/LottieLoading";
import CssBaseline from '@mui/material/CssBaseline';
import {
  Alert,
  Box,
  Button, Card, CardActions, CardContent,
  Divider,
  Drawer,
  Fab,
  Fade, IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText, Menu, MenuItem,
  Modal,
  Snackbar, Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import {
  apiDownloadGPXToGeoJSON,
  apiGetOpenExplorePoints,
  apiGetOpenExploreTrails,
  apiGetOpenExploreTypes
} from "./api/apiOpenExplore";
import PointDetail from "./components/PointDetail";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PointListItem from "./components/PointListItem";
import {ignLayer} from "./constants/mapbox";
import MenuIcon from '@mui/icons-material/Menu';
import {getClosestPoint, getElevationProfile} from "./services/elevationProfileService";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function App() {
  const [showFilters, setShowFilters] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [points, setPoints] = React.useState([]);
  const [trails, setTrails] = React.useState([]);
  const [selectedPoint, setSelectedPoint] = React.useState(null);
  const [geojson, setGeojson] = React.useState(null);
  const [types, setTypes] = React.useState([]);
  const [filteredTypes, setFilteredTypes] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [showElevationProfile, setShowElevationProfile] = React.useState(false);
  const [elevationProfilePoints, setElevationProfilePoints] = React.useState([]);
  const [elevationProfile, setElevationProfile] = React.useState(null);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = React.useState(null);
  const defaultMapCenter = {
    latitude: 44.3560683,
    longitude: 3.6041091,
    zoom: 8
  };
  const detailPanelWidth = 450;
  let mapboxMap = null;
  const isMobile = width <= 768;
  const isMobileMenuOpen = Boolean(mobileMenuAnchorEl);

  useEffect(() => {
    if (loading === false && points.length === 0 && trails.length === 0 && types.length === 0 && error === null) {
      loadData()
        .catch(console.error);
    }
  })

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    if (mapboxMap != null) {
      if (selectedPoint != null) {
        mapboxMap.flyTo({
          center: [selectedPoint.longitude, selectedPoint.latitude],
          zoom: 14,
          padding: {
            left: isMobile ? 0 : detailPanelWidth
          }
        });
      } else {
//        mapboxMap.flyTo({
//          center: [defaultMapCenter.longitude, defaultMapCenter.latitude],
//          zoom: defaultMapCenter.zoom,
//          padding: {
//            left: 0
//          }
//        });
        mapboxMap.setPadding({
          left: 0
        })
      }
    }
  }, [isMobile, mapboxMap, selectedPoint])

  useEffect(() => {
    if (geojson === null && trails.length !== 0) {
      let trail = trails.filter((trail) => trail.tag === "chemin")[0]

      if (trail) {
        apiDownloadGPXToGeoJSON(trail)
          .then((geojson) => {
            setGeojson(geojson);
            console.info("GeoJSON", geojson)
          })
          .catch((error) => {
            setError(error)
            console.error(error);
          });
      }
    }
  }, [trails, geojson])

  useEffect(() => {
    if (elevationProfilePoints.length === 2) {
      console.log("COMPUTE ELEVATION PROFILE", elevationProfilePoints);

      if (geojson) {
        const [closestStartPoint, closestStartIndex] = getClosestPoint(elevationProfilePoints[0], geojson);
        console.log("Closest Start point", closestStartPoint, closestStartIndex);
        const [closestEndPoint, closestEndIndex] = getClosestPoint(elevationProfilePoints[1], geojson);
        console.log("Closest End point", closestEndPoint, closestEndIndex);
        const elevationProfile = getElevationProfile(closestStartPoint, closestStartIndex, closestEndPoint, closestEndIndex, geojson);
        console.log("Elevation Profile", elevationProfile);
        setElevationProfile(elevationProfile);
      } else {
        console.error("Elevation profile cannot be computed, missing GeoJSON data.");
      }
    }
  }, [elevationProfilePoints, geojson])

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  const addPointToElevationProfile = (mapboxEvent) => {
    if (showElevationProfile) {
      if (elevationProfilePoints.length < 2) {
        console.info("Point added to elevation profile", mapboxEvent);
        setElevationProfilePoints([...elevationProfilePoints, mapboxEvent.lngLat]);
      } else {
        console.info("Map clic ignored, elevation profile is already full", mapboxEvent);
      }
    } else {
      console.info("Map clic ignored, not in elevation profile mode", mapboxEvent);
    }
  }

  const loadData = async () => {
    setLoading(true);

    try {
      const [typesResponse, pointsResponse, trailsResponse] = await Promise.all([
        apiGetOpenExploreTypes(),
        apiGetOpenExplorePoints(),
        apiGetOpenExploreTrails()
      ])

      let trails = trailsResponse.data;
      let points = pointsResponse.data;
      let types = typesResponse.data.filter((type) => {
        return points.find((point) => {
          return point.types_id.includes(type.id)
        }) !== undefined;
      })

      console.log('Types', types);
      setTypes(types);
      setFilteredTypes(types)
      console.log('Points', points);
      setPoints(points);
      console.log('Trails', trails);
      setTrails(trails);
    } catch (error) {
      setError(error)
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const openSearchDrawer = () => {
    setMobileMenuAnchorEl(null)
    setShowSearch(true);
    setShowElevationProfile(false);
    setElevationProfilePoints([]);
    setElevationProfile(null);
  }

  const openFilterDrawer = () => {
    setMobileMenuAnchorEl(null)
    setShowFilters(true);
    setShowElevationProfile(false);
    setElevationProfilePoints([]);
    setElevationProfile(null);
  }

  const openElevationProfileDrawer = () => {
    setMobileMenuAnchorEl(null)
    setShowElevationProfile(true)
  }

  return (
    <React.Fragment>
      <CssBaseline/>

      {/* Elevation Profile */}
      <Fade in={showElevationProfile}>
        <Card
          sx={{
            position: "absolute",
            color: "#1F3060",
            maxWidth: isMobile ? null : 500,
            width: isMobile ? '100%' : 500,
            margin: 0,
            zIndex: 10,
            backgroundColor: "white",
            right: isMobile ? null : 16,
            bottom: isMobile ? 0 : 16
          }}>
          <CardContent>
            <Typography align="center" variant={"h6"}>
              Distance / Dénivelé
            </Typography>
            {!elevationProfile && (
              <Typography align="center" variant={"subtitle1"}>
                Veuillez sélectionner 2 points sur la carte afin de calculer le profil altimétrique (distance / dénivelé) entre ces points.
              </Typography>
            )}
            {/*{elevationProfilePoints.map((point, index) => (*/}
            {/*  <Typography variant={"subtitle1"}>*/}
            {/*    {index === 0 && (*/}
            {/*      "Point de départ:"*/}
            {/*    )}*/}
            {/*    {index === 1 && (*/}
            {/*      "Point d'arrivée:"*/}
            {/*    )}*/}
            {/*    {point.lng}, {point.lat}*/}
            {/*  </Typography>*/}
            {/*))}*/}
            {elevationProfile && (
              <div>
                <Typography variant={"subtitle1"}>
                  Distance : {Math.round(elevationProfile.distance / 1000)} km
                </Typography>
                <Typography variant={"subtitle1"}>
                  D+ : {Math.round(elevationProfile.positiveElevation)} m
                </Typography>
                <Typography variant={"subtitle1"}>
                  D- : {Math.round(elevationProfile.negativeElevation)} m
                </Typography>
                <Divider
                  style={{
                    margin: "8px 0 8px 0"
                  }}
                />
                <Line options={elevationProfile.options} data={elevationProfile.data}/>
              </div>
            )}
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => {
                setShowElevationProfile(false);
                setElevationProfilePoints([]);
                setElevationProfile(null);
              }}
            >
              Fermer
            </Button>
            <Button
              size="small"
              onClick={() => {
                setElevationProfilePoints([]);
                setElevationProfile(null);
              }}
            >
              Réinitialiser
            </Button>
          </CardActions>
        </Card>
      </Fade>

      {/* Mobile menu */}
      {isMobile && (
        <Fab
          sx={{
            position: "absolute",
            color: "white",
            backgroundColor: "#1F3060",
            '&:hover': {
              color: "white",
              backgroundColor: "#031427",
            },
            right: 16,
            top: 16
          }}
          variant="extended"
          onClick={(event) => {
            setMobileMenuAnchorEl(event.currentTarget)
          }}
        >
          <MenuIcon sx={{mr: 1}}/>
          Menu
        </Fab>
      )}
      <Menu
        id="basic-menu"
        anchorEl={mobileMenuAnchorEl}
        open={isMobileMenuOpen}
        onClose={() => {
          setMobileMenuAnchorEl(null)
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={openSearchDrawer}>
          <ListItemIcon>
            <SearchIcon fontSize="small"/>
          </ListItemIcon>
          <ListItemText>Rechercher</ListItemText>
        </MenuItem>
        <MenuItem onClick={openFilterDrawer}>
          <ListItemIcon>
            <FilterAltIcon fontSize="small"/>
          </ListItemIcon>
          <ListItemText>Filtrer</ListItemText>
        </MenuItem>
        <MenuItem onClick={openElevationProfileDrawer}>
          <ListItemIcon>
            <TrendingUpIcon fontSize="small"/>
          </ListItemIcon>
          <ListItemText>Distance / Dénivelé</ListItemText>
        </MenuItem>
      </Menu>

      {/* Drawer Bottom Right - Elevation Profil */}
      {!showElevationProfile && !isMobile && (
        <Fab
          sx={{
            position: "absolute",
            color: "white",
            backgroundColor: "#1F3060",
            '&:hover': {
              color: "white",
              backgroundColor: "#031427",
            },
            right: 16,
            bottom: 16
          }}
          variant="extended"
          onClick={openElevationProfileDrawer}
        >
          <TrendingUpIcon sx={{mr: 1}}/>
          Distance / Dénivelé
        </Fab>
      )}

      {/* Drawer Top Left - Search */}
      {!isMobile && (
        <Fab
          sx={{
            position: "absolute",
            color: "white",
            backgroundColor: "#1F3060",
            '&:hover': {
              color: "white",
              backgroundColor: "#031427",
            },
            left: 16,
            top: 16
          }}
          variant="extended"
          onClick={openSearchDrawer}
        >
          <SearchIcon sx={{mr: 1}}/>
          Rechercher
        </Fab>
      )}
      <Drawer
        anchor='left'
        open={showSearch}
        onClose={() => {
          setShowSearch(false);
          setSearchQuery(null);
        }}
      >
        <Box
          sx={{width: isMobile ? '100vw' : 350, p: 2}}
          role="presentation"
        >
          <Box>
            <Stack
              direction="row"
              justifyContent="space-between"
            >
              <Typography variant={"h5"} style={{fontWeight: 600}}>
                Recherche
              </Typography>
              <IconButton onClick={() => {
                setShowSearch(false);
                setSearchQuery(null);
              }}>
                <CloseIcon/>
              </IconButton>
            </Stack>
          </Box>
          <TextField
            id="input-with-icon-textfield"
            autoFocus={true}
            fullWidth={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon/>
                </InputAdornment>
              ),
            }}
            variant="standard"
            onChange={(event) => {
              setSearchQuery(event.target.value);
            }}
          />
          <Divider/>
          <List>
            {points
              .filter((point) => {
                if (searchQuery !== null && searchQuery.length > 0) {
                  return point.name.toLowerCase().includes(searchQuery.toLowerCase());
                }

                return true;
              })
              .map((point) => (
                <ListItem key={point.id} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setShowSearch(false)
                      setSearchQuery(null);
                      setSelectedPoint(point)
                    }}
                  >
                    <PointListItem
                      point={point}
                      types={types}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Box>
      </Drawer>

      {/* Drawer Top Right - Filters */}
      {!isMobile && (
        <Fab
          sx={{
            position: "absolute",
            color: "white",
            backgroundColor: "#1F3060",
            '&:hover': {
              color: "white",
              backgroundColor: "#031427",
            },
            right: 16,
            top: 16
          }}
          variant="extended"
          onClick={openFilterDrawer}
        >
          <FilterAltIcon sx={{mr: 1}}/>
          Filtrer
        </Fab>
      )}
      <Drawer
        anchor='right'
        open={showFilters}
        onClose={() => {
          setShowFilters(false);
        }}
      >
        <Box
          sx={{width: isMobile ? '100vw' : 350}}
          role="presentation"
        >
          <Box sx={{p: 2}}>
            <Stack
              direction="row"
              justifyContent="space-between"
            >
              <Typography variant={"h5"} style={{
                fontWeight:
                  600
              }}>
                Filtre
              </Typography>
              <IconButton onClick={() => {
                setShowFilters(false);
              }}>
                <CloseIcon/>
              </IconButton>
            </Stack>
          </Box>
          <Divider/>
          <List>
            {types.map((type) => (
              <ListItem key={type.id} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <img src={type.picture} width={30} alt={""}/>
                  </ListItemIcon>
                  <ListItemText primary={type.name}/>
                  <ListItemSecondaryAction>
                    <Switch
                      checked={filteredTypes.includes(type)}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setFilteredTypes([...filteredTypes, type])
                        } else {
                          setFilteredTypes(filteredTypes.filter((elt) => elt.id !== type.id))
                        }
                      }}
                      inputProps={{'aria-label': 'controlled'}}
                    />
                  </ListItemSecondaryAction>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      {/* Drawer Left - Point Detail */}
      <Drawer
        anchor='left'
        open={selectedPoint !== null}
        onClose={() => {
          setSelectedPoint(null);
        }}
      >
        <Box
          sx={{width: isMobile ? '100vw' : detailPanelWidth}}
          role="presentation"
        >
          {selectedPoint && (

            <PointDetail
              point={selectedPoint}
              types={types}
              onClose={() => {
                setSelectedPoint(null);
              }}
            />
          )}
        </Box>
      </Drawer>

      {/* Error */}
      <Snackbar open={error !== null}>
        <Alert
          severity="error"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                loadData()
              }}
            >
              Réessayer
            </Button>
          }
        >
          Une erreur est survenue lors du chargement des données
          {error && (
            <span><br/> ({error.message})</span>
          )}
        </Alert>
      </Snackbar>

      {/* Loader */}
      <Modal
        open={loading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={loading}>
          <Box>
            <LottieLoading/>
          </Box>
        </Fade>
      </Modal>

      <Map
        ref={ref => {
          mapboxMap = ref;
        }}
        initialViewState={{
          longitude: defaultMapCenter.longitude,
          latitude: defaultMapCenter.latitude,
          zoom: defaultMapCenter.zoom
        }}
        mapboxAccessToken={'pk.eyJ1IjoidXJiYWludiIsImEiOiJjbDc4MGd6aXgwNTJtM29tYzB6c2U1ZXN1In0.VRuSsYOx80GutNwNmMaWXA'}
        style={{height: '100vh'}}
        mapStyle='mapbox://styles/mapbox/outdoors-v11'
        onClick={(event) => {
          addPointToElevationProfile(event);
        }}
        cooperativeGestures={window.self !== window.top} // In iframe
      >
        {/* Points du profil altimétrique */}
        {elevationProfilePoints.map((point, index) => (
          <Marker
            latitude={point.lat}
            longitude={point.lng}
            anchor={"center"}
            color={'#BF0603'}
            key={index}
          />
        ))}

        {/* Points POIs */}
        {points
          .filter((point) => {
            return point.types_id.reduce((previous, typeId) => {
                return filteredTypes.map((type) => type.id).includes(typeId) || previous
              }, false
            );
          })
          .map((point) => {
            const typeId = point.types_id[0]
            const type = types.filter((type) => type.id === typeId)[0]
            const isSelectedPoint = selectedPoint != null && selectedPoint.id === point.id;
            return (
              <Marker
                  style={{
                    zIndex: isSelectedPoint ? 1000 : 500
                  }}
                latitude={point.latitude}
                longitude={point.longitude}
                anchor={type ? "center" : "bottom"}
                color={'#BF0603'}
                key={point.id}

                onClick={(event) => {
                  if (!showElevationProfile) {
                    setSelectedPoint(point);
                  }
                }}
              >
                {type && (
                    <img
                        style={{
                          zIndex: isSelectedPoint ? 1000 : 500,
                          borderRadius: '50%',
                          border: isSelectedPoint ? '5px solid red' : ''
                        }}
                        src={type.picture}
                        width={isSelectedPoint ? 40 : 30}
                        alt={""}
                    />
                )}
              </Marker>
            );
          })}

        {/* IGN Layer */}
        <Source
          id='ignSource'
          type='raster'
          tiles={["https://wxs.ign.fr/cartes/geoportail/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2&TILEMATRIXSET=PM&TILEMATRIX={z}&TILECOL={x}&TILEROW={y}&STYLE=normal&FORMAT=image/png"]}
          tileSize={256}
        >
          <Layer {...ignLayer} />
        </Source>

        {/* Trail */}
        {/* https://stackoverflow.com/a/66379268/1984760 */}
        {/* https://retz.dev/blog/display-map-data-with-react-and-mapbox */}
        {geojson && (
          <Source id="track" type="geojson" data={geojson}>
            <Layer
              id='running-routes-line'
              type='line'
              source='running-routes'
              paint={{
                'line-color': "#1F3060",
                'line-width': 7
              }}
            />
          </Source>
        )}
      </Map>
    </React.Fragment>
  )
    ;
}

export default App;
