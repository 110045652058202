import './PointListItem.css';
import React, {useEffect} from 'react';
import {Box, Stack, Typography} from "@mui/material";

const PointListItem = ({point, types}) => {
  const [type, setType] = React.useState(undefined);

  useEffect(() => {
    if (type === undefined) {
      const typeId = point.types_id[0]

      if (typeId) {
        const type = types.filter((type) => type.id === typeId)[0]
        setType(type);
      }
    }
  }, [point.types_id, type, types]);

  let media = point.medias[0];

  return (
    <React.Fragment>
      <Stack direction="row" spacing={2}>
        {media && (
          <img
            className={"pointListItemImage"}
            src={media.link}
            width={100}
            height={100}
            alt={""}
          />
        )}
        <Stack spacing={2}>
          <Box sx={{paddingTop: 1, paddingBottom: 1}}>
            <Typography align="left" variant="body1">
              {point.name}
            </Typography>
            {type && (
              <Typography
                align="left"
                variant="subtitle2"
                className={"detailType"}
              >
                {type.name}
              </Typography>
            )}
          </Box>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};

export default PointListItem;
